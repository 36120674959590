import React from "react";

const data = [
  {
    title: "網站鏈結",
    isExternal: false,
    links: [
      {
        title: "主頁",
        href: "#home",
      },
      {
        title: "簡介",
        href: "#about",
      },
      {
        title: "練習",
        href: "#modes",
      },
      {
        title: "特色",
        href: "#features",
      },
      {
        title: "價錢",
        href: "#pricing",
      },
      {
        title: "Q&A",
        href: "#qa",
      },
      {
        title: "評價",
        href: "#testimonial",
      },
      {
        title: "下載",
        href: "#download",
      },
    ],
  },
  {
    title: "幫助",
    isExternal: true,
    links: [
      {
        title: "最新消息",
        href: "https://docs.cangjie.app/blog/",
      },
      {
        title: "支援",
        href: "https://help.cangjie.app/index.php?a=add",
      },
      {
        title: "指南",
        href: "https://docs.cangjie.app/",
      },
      {
        title: "常見問題",
        href: "https://docs.cangjie.app/docs/info/qa",
      },
      {
        title: "私隱政策",
        href: "https://docs.cangjie.app/docs/info/privacy-policy",
      },
      {
        title: "更新日誌",
        href: "https://docs.cangjie.app/docs/info/changelogs",
      },
    ],
  },
  {
    title: "下載",
    isExternal: true,
    links: [
      {
        title: "Google Play",
        href: "https://play.google.com/store/apps/details?id=com.opensertk.cangjie_practice_ss_directload",
      },
      {
        title: "App Store",
        href: "https://apps.apple.com/hk/app/%E5%80%89%E9%A0%A1-%E9%80%9F%E6%88%90%E7%B7%B4%E7%BF%92%E5%B7%A5%E5%85%B7/id1600176379",
      },
      {
        title: "AppGallery",
        href: "https://appgallery.huawei.com/app/C102894033",
      },
    ],
  },
  {
    title: "其他鏈結",
    isExternal: true,
    links: [
      {
        title: "Facebook",
        href: "https://www.facebook.com/tungtohk",
      },
    ],
  },
];

const FooterColumn = ({ column }) => {
  return (
    <div className="flex flex-grow flex-col items-center pb-8">
      <p className="font-bold text-xl text-white mb-4">{column.title}</p>
      {column.links.map((link) => (
        <a
          className="text-white mb-2"
          href={link.href}
          target={column.isExternal ? "_blank" : "_self"}
          key={link.href}
        >
          {link.title}
        </a>
      ))}
    </div>
  );
};

const Footer = () => {
  return (
    <footer className="bg-darkblue-800">
      <div className="flex flex-col lg:flex-row">
        {data.map((column) => (
          <FooterColumn column={column} key={column.title} />
        ))}
      </div>
      <div>
        <p className="text-white p-4 text-center">
          版權所有© 2022{" "}
          <a href="https://tungto.hk" target="_blank">
            Tung To Technology
          </a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
